html.lightbox-open {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

.lightbox-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0px;
  left: 0px;
  z-index: 11000;
}

.lightbox-btn-left,
.lightbox-btn-right,
.lightbox-btn-close,
.lightbox-title-content,
.lightbox-modifiers-box {
  transition: opacity 200ms ease;
}

@media (max-width: 767px) {
  .hide-controls .lightbox-btn-left,
  .hide-controls .lightbox-btn-right {
    opacity: 0;
    pointer-events: none;
  }
}

@media (max-height: 599px) {
  .hide-controls .lightbox-btn-close,
  .hide-controls .lightbox-title-content,
  .hide-controls .lightbox-modifiers-box {
    opacity: 0;
    pointer-events: none;
  }
}

.lightbox-btn-close {
  position: fixed;
  right: 0px;
  z-index: 2;
}

.lightbox-btn-right {
  position: absolute;
  top: 50%;
  margin-top: -1.5em;
  right: 0px;
}

.lightbox-btn-left {
  position: absolute;
  top: 50%;
  margin-top: -1.5em;
  left: 0px;
}

@media (min-width: 768px) {
  .lightbox-btn-right {
    padding-right: 0;
  }

  .lightbox-btn-left {
    padding-left: 0;
  }
}

.lightbox-title-content {
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12);
  position: absolute;
  width: 100%;
  z-index: 1;
  bottom: 0;
  text-align: center;
  padding: 10px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  line-height: 1.42857143;
}

.lightbox-title {
  color: white;
  font-weight: 600;
  font-size: 16px;
}

.lightbox-description {
  color: #C5C5C5;
  font-size: 14px;
}

.lightbox-transition-image-right-appear {
  opacity: 0.1;
}

.lightbox-transition-image-right-appear.lightbox-transition-image-right-appear-active {
  opacity: 1;
  transition: opacity .3s ease-in;
}

.lightbox-transition-image-left-appear {
	opacity: 0.1;
  }
  
  .lightbox-transition-image-left-appear.lightbox-transition-image-left-appear-active {
	opacity: 1;
	transition: opacity .3s ease-in;
  }

.lightbox-transition-image-right-enter {
  transform: translate(100%);
}

.lightbox-transition-image-right-enter.lightbox-transition-image-right-enter-active {
  transform: translate(0%);
  transition: transform 300ms ease-in-out;
}

.lightbox-transition-image-right-exit {
  transform: translate(0%);
}

.lightbox-transition-image-right-exit.lightbox-transition-image-right-exit-active {
  transform: translate(-100%);
  transition: transform 300ms ease-in-out;
}

.lightbox-transition-image-left-enter {
  transform: translate(-100%);
}

.lightbox-transition-image-left-enter.lightbox-transition-image-left-enter-active {
  transform: translate(0%);
  transition: transform 300ms ease-in-out;
}

.lightbox-transition-image-left-exit {
  transform: translate(0%);
}

.lightbox-transition-image-left-exit.lightbox-transition-image-left-exit-active {
  transform: translate(100%);
  transition: transform 300ms ease-in-out;
}

.right-to-left-enter {
    transform: translateX(100%);
}
.right-to-left-enter-active {
    transform: translateX(0);
    transition:all 1s ease;
}      

.right-to-left-exit {
    transform: translateX(0);
}
.right-to-left-exit-active {
    transform: translateX(-100%);
    transition:all 1s ease;
}      

.left-to-right-enter {
    transform: translateX(-100%);
}
.left-to-right-enter-active {
    transform: translateX(0);
    transition:all 1s ease;
}      

.left-to-right-exit {
    transform: translateX(0);
}
.left-to-right-exit-active {
    transform: translateX(100%);
    transition:all 1s ease;
} 