/* Customize the Clustered Markers */
.marker-cluster-custom {
    background: #3f94d9;
    box-shadow: 0 0 0 15px rgba(60, 153, 220, .4);
    border: 1px solid rgba(255, 255, 255, .2);
    color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s;
    z-index: 0;
}

.marker-cluster-custom:hover {
    box-shadow: 0 0 0 25px rgba(60, 153, 220, .2);
}

.marker-custom {
    background: #3f94d9;
    border: 1px solid rgba(255, 255, 255, .2);
    box-shadow: 0 0 0 10px rgba(60, 153, 220, .2);
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s;
    z-index: 0;
}

.marker-custom:hover {
    box-shadow: 0 0 0 20px rgba(60, 153, 220, .2);
}

.marker-custom img {
    width: 20px;
    height: 20px;
}

.marker-custom-active {
    background: #FFD700;
    box-shadow: 0 0 0 10px rgba(255, 239, 0, .5);
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s;
    z-index: 0;
}

.marker-custom-active:hover {
    box-shadow: 0 0 0 20px rgba(255, 239, 0, .3);
}

.marker-custom-active img {
    width: 20px;
    height: 20px;
}