/* appear - on page load */
.fade-appear {
    opacity: 0;
}
.fade-appear.fade-appear-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

/* enter */
.fade-enter {
    opacity: 0;
}
.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

/* exit */
.fade-exit {
    opacity: 1;
}
.fade-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
}
.fade-exit-done {
    opacity: 0;
}