@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');


body {
    margin: 0;
    font-family: 'Arial', sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-container {
    width: 100%;
    height: 100vh;
}

.leaflet-pane {
    z-index: 400;
}

button {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
    background-color: transparent !important;
}

button:focus {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
    background-color: transparent !important;
}